import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';

import { appRoutes } from './app.routes';
import { interceptors, provideCore } from './core';
import { ENVIRONMENT } from './core/models/environment.model';

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, '../assets/i18n/', '.json');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({
        canceledNavigationResolution: 'computed',
      }),
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors(interceptors)),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: 'ja',
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
    provideCore(),
    { provide: ENVIRONMENT, useValue: environment },
  ],
};
