import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Completion,
  CompletionCreateParams,
  ExecutionTimeParam,
  Prediction,
  QuerySuggestion,
  RecordFeedbackParams,
  Reference,
  UnitNameItem,
  UpdateSuggestionScoreParams,
} from '../models/chat.model';
import { HIDE_PROGRESS } from '../models/utility.model';
import { ChatGateway } from '../usecases/chat.gateway';

@Injectable()
export class ChatService extends ChatGateway {
  private readonly _http = inject(HttpClient);

  // initial download

  getUnitNameTable(url: string): Observable<UnitNameItem> {
    return this._http.get<UnitNameItem>(url);
  }

  // complition

  createCompletion(params: CompletionCreateParams): Observable<Completion> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.post<Completion>('api/ask/completions', params, options);
  }

  // prediction

  listPredictionWords(words: string): Observable<Prediction> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.get<Prediction>(`api/ask/predictions/${words}`, options);
  }

  // record

  updateFeedback(queryId: string, params: RecordFeedbackParams): Observable<{ message: string }> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<{ message: string }>(`api/ask/records/${queryId}`, params, options);
  }

  // reference

  getReference(docKey: string, isOriginal?: boolean): Observable<Reference> {
    if (isOriginal) {
      return this._http.get<Reference>(`api/ask/references/${docKey}?isOriginal=true`);
    }
    return this._http.get<Reference>(`api/ask/references/${docKey}`);
  }

  // suggestion

  getQuerySuggestion(question: string): Observable<QuerySuggestion> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.get<QuerySuggestion>(`api/ask/suggestions/${encodeURIComponent(question)}`, options);
  }

  // suggestion-score

  updateSuggestionScore(terms: string, params: UpdateSuggestionScoreParams): Observable<{ message: string }> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<{ message: string }>(`api/ask/suggestion-scores/${encodeURIComponent(terms)}`, params, options);
  }

  // time

  updateExecutionTime(date: number, params: ExecutionTimeParam): Observable<{ message: string }> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<{ message: string }>(`api/ask/times/${date}`, params, options);
  }
}
