import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthInteractor } from './interactors/auth.interactor';
import { ChatInteractor } from './interactors/chat.interactor';
import { ProgressInteractor } from './interactors/progress.interactor';
import { ToastInteractor } from './interactors/toast.interactor';
import { httpInterceptorProviders } from './interceptors';
import { AuthService } from './services/auth.service';
import { CompletionService } from './services/completion.service';
import { PredictionService } from './services/prediction.service';
import { RecordService } from './services/record.service';
import { ReferenceService } from './services/reference.service';
import { TimeService } from './services/time.service';
import { AuthGateway } from './usecases/auth.gateway';
import { AuthUsecase } from './usecases/auth.usecase';
import { ChatUsecase } from './usecases/chat.usecase';
import { CompletionGateway } from './usecases/completion.gateway';
import { PredictionGateway } from './usecases/prediction.gateway';
import { ProgressUsecase } from './usecases/progress.usecase';
import { RecordGateway } from './usecases/record.gateway';
import { ReferenceGateway } from './usecases/reference.gateway';
import { TimeGateway } from './usecases/time.gateway';
import { ToastUsecase } from './usecases/toast.usecase';

@NgModule({
  providers: [
    httpInterceptorProviders,
    { provide: AuthUsecase, useClass: AuthInteractor },
    { provide: ChatUsecase, useClass: ChatInteractor },
    { provide: ProgressUsecase, useClass: ProgressInteractor },
    { provide: ToastUsecase, useClass: ToastInteractor },
    { provide: AuthGateway, useClass: AuthService },
    { provide: CompletionGateway, useClass: CompletionService },
    { provide: PredictionGateway, useClass: PredictionService },
    { provide: RecordGateway, useClass: RecordService },
    { provide: ReferenceGateway, useClass: ReferenceService },
    { provide: TimeGateway, useClass: TimeService },
  ],
  imports: [CommonModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule Only.');
    }
  }
}
