import { bootstrapApplication } from '@angular/platform-browser';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.locale('ja');
dayjs.tz.setDefault('Asia/Tokyo');

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
