import { Injectable, inject } from '@angular/core';
import { ConfirmResetPasswordInput, ConfirmSignInInput, ResetPasswordInput, SignInInput } from 'aws-amplify/auth';
import { AsyncSubject, BehaviorSubject, Observable, finalize, map } from 'rxjs';

import { AuthSignInStep, isAuthSignInStep } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';
import { AuthUseCase } from '../usecases/auth.usecase';
import { ProgressUseCase } from '../usecases/progress.usecase';

@Injectable()
export class AuthInteractor extends AuthUseCase {
  private readonly _authGateway = inject(AuthGateway);
  private readonly _progressUseCase = inject(ProgressUseCase);
  private readonly _authStep = new BehaviorSubject<AuthSignInStep>('NONE');
  private readonly _username = new BehaviorSubject<string>('');

  constructor() {
    super();
    this._authGateway.fetchAuthSession().subscribe({
      next: session => (session.tokens?.idToken ? this._authStep.next('DONE') : this._authStep.next('NONE')),
    });
  }

  override get authStep$(): Observable<AuthSignInStep> {
    return this._authStep;
  }
  override get token$(): Observable<string> {
    return this._authGateway.fetchAuthSession().pipe(map(session => session.tokens?.idToken?.toString() ?? ''));
  }
  override get username$(): Observable<string> {
    return this._username;
  }
  override set nextStep(step: AuthSignInStep) {
    if (isAuthSignInStep(step)) {
      this._authStep.next(step);
    }
  }

  override signIn(params: SignInInput): Observable<never> {
    this._username.next(params.username);
    return this.executeWithProgress(
      () => this._authGateway.signIn(params),
      nextStep => this._authStep.next(nextStep),
    );
  }

  override confirmSignIn(params: ConfirmSignInInput): Observable<never> {
    return this.executeWithProgress(
      () => this._authGateway.confirmSignIn(params),
      nextStep => this._authStep.next(nextStep),
    );
  }

  override signOut(): Observable<never> {
    return this.executeWithProgress(
      () => this._authGateway.signOut(),
      undefined,
      () => this._authStep.next('NONE'),
    );
  }

  override resetPassword(params: ResetPasswordInput): Observable<never> {
    return this.executeWithProgress(
      () => this._authGateway.resetPassword(params),
      nextStep => this._authStep.next(nextStep),
    );
  }

  override confirmResetPassword(params: ConfirmResetPasswordInput): Observable<never> {
    return this.executeWithProgress(
      () => this._authGateway.confirmResetPassword(params),
      undefined,
      () => this._authStep.next('NONE'),
    );
  }

  private executeWithProgress<T>(action: () => Observable<T>, onNext?: (result: T) => void, onComplete?: () => void): Observable<never> {
    const progressId = this._progressUseCase.show();
    const result = new AsyncSubject<never>();

    action()
      .pipe(finalize(() => this._progressUseCase.dismiss(progressId)))
      .subscribe({
        next: value => {
          onNext?.(value);
          result.complete();
        },
        error: error => result.error(error),
        complete: () => {
          onComplete?.();
          result.complete();
        },
      });

    return result;
  }
}
