import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RecordFeedbackParams, UpdateSuggestionScoreParams } from '../models/chat.model';
import { HIDE_PROGRESS } from '../models/utility.model';
import { RecordGateway } from '../usecases/record.gateway';

@Injectable()
export class RecordService extends RecordGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  updateFeedbuck(queryId: string, params: RecordFeedbackParams): Observable<{ message: string }> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<{ message: string }>(`api/ask/records/${queryId}`, params, options);
  }

  updateSuggestionScore(terms: string, params: UpdateSuggestionScoreParams): Observable<{ message: string }> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<{ message: string }>(`api/ask/suggestion-score/${encodeURIComponent(terms)}`, params, options);
  }
}
