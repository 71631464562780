import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENVIRONMENT, Environment } from '../models/environment.model';

@Injectable()
export class CorsInterceptor implements HttpInterceptor {
  constructor(@Inject(ENVIRONMENT) private _environment: Environment) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('api') && location.host === 'localhost:4200') {
      return next.handle(this.rewriteUrl(req));
    }
    return next.handle(req);
  }

  private rewriteUrl(req: HttpRequest<unknown>): HttpRequest<unknown> {
    return req.clone({
      url: new URL(req.url, this._environment.apiUrl).href,
    });
  }
}
