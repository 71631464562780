import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { NgbDropdownConfig, NgbModal, NgbModalConfig, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { hide } from '@popperjs/core';

import { AuthUseCase } from './core/usecases/auth.usecase';
import { ProgressUseCase } from './core/usecases/progress.usecase';
import { ToastUseCase } from './core/usecases/toast.usecase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgbToast, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly _dropdownConfig = inject(NgbDropdownConfig);
  private readonly _modal = inject(NgbModal);
  private readonly _modalConfig = inject(NgbModalConfig);
  private readonly _router = inject<Router>(Router);
  private readonly _translateService = inject(TranslateService);

  private readonly _authUseCase = inject(AuthUseCase);
  private readonly _progressUseCase = inject(ProgressUseCase);
  private readonly _toastUseCase = inject(ToastUseCase);

  readonly isProgress = toSignal(this._progressUseCase.progress$, { initialValue: false });
  readonly toasts = toSignal(this._toastUseCase.toasts$, { initialValue: [] });
  readonly step = toSignal(this._authUseCase.authStep$, { initialValue: 'NONE' });

  constructor() {
    this._dropdownConfig.popperOptions = ({ modifiers = [], ...surplus }): Partial<NgbDropdownConfig['popperOptions']> => ({
      ...surplus,
      modifiers: [...modifiers, hide],
    });
    this._modalConfig.backdrop = 'static';
    this._modalConfig.keyboard = false;
    this._modalConfig.scrollable = true;
    this._translateService.addLangs(['en', 'ja']);
    this._translateService.setDefaultLang('ja');
    this._translateService.use('ja');

    effect(() => {
      const nextStep = this.step();
      if (nextStep !== 'DONE' && !location.pathname.startsWith('/auth')) {
        this._modal.dismissAll();
        this._router.navigate(['/auth']);
      } else if (nextStep === 'DONE' && location.pathname === '/pdf') {
        this._modal.dismissAll();
        this._router.navigate([`/pdf`], { fragment: location.hash.substring(1) });
      }
    });
  }
}
