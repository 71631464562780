import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { HIDE_PROGRESS } from '../models/utility.model';
import { ProgressUsecase } from '../usecases/progress.usecase';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  constructor(private _progressUsecase: ProgressUsecase) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.context.get(HIDE_PROGRESS)) {
      return next.handle(req);
    }
    const progressId = this._progressUsecase.show();
    return next.handle(req).pipe(finalize(() => this._progressUsecase.dismiss(progressId)));
  }
}
