import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { mergeMap } from 'rxjs/operators';

import { AuthUseCase } from '../usecases/auth.usecase';

export const IGNORE_AUTH = new HttpContextToken(() => false);

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.context.get(IGNORE_AUTH)) {
    return next(req);
  }
  const authUseCase = inject(AuthUseCase);
  if (req.url.startsWith('api')) {
    return authUseCase.token$.pipe(mergeMap(token => next(req.clone({ setHeaders: { authorization: token } }))));
  }
  return next(req);
};
