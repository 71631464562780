import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Toast, ToastType } from '../models/toast.model';
import { ToastUsecase } from '../usecases/toast.usecase';

@Injectable()
export class ToastInteractor extends ToastUsecase {
  get toasts$(): Observable<Toast[]> {
    return this._toasts;
  }

  private readonly _toasts = new BehaviorSubject<Toast[]>([]);

  show(message: string, type: ToastType, delay = 5000): void {
    const toast = {
      message,
      type,
      delay,
      hide: () => {
        this._toasts.next(this._toasts.value.filter(t => t !== toast));
      },
    };
    this._toasts.next(this._toasts.value.concat(toast));
  }
}
