import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { ENVIRONMENT } from '../models/environment.model';

export const corsInterceptor: HttpInterceptorFn = (req, next) => {
  if (location.host !== 'localhost:4200') {
    return next(req);
  }
  const environment = inject(ENVIRONMENT);
  if (req.url.startsWith('api') && environment.apiUrl) {
    return next(req.clone({ url: new URL(req.url, environment.apiUrl).href }));
  }
  return next(req);
};
