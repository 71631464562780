import { Injectable, inject } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {
  AuthSession,
  ConfirmResetPasswordInput,
  ConfirmSignInInput,
  ResetPasswordInput,
  SignInInput,
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  resetPassword,
  signIn,
  signOut,
} from 'aws-amplify/auth';
import { Observable, defer, map } from 'rxjs';

import { AuthSignInStep } from '../models/auth.model';
import { ENVIRONMENT } from '../models/environment.model';
import { AuthGateway } from '../usecases/auth.gateway';

@Injectable()
export class AuthService extends AuthGateway {
  private readonly _environment = inject(ENVIRONMENT);

  constructor() {
    super();
    const { userPoolId, userPoolClientId } = this._environment;
    Amplify.configure({
      ['Auth']: {
        ['Cognito']: { userPoolId, userPoolClientId },
      },
    });
  }

  override signIn(params: SignInInput): Observable<AuthSignInStep> {
    return defer(async () => await signIn(params)).pipe(map(output => output.nextStep.signInStep));
  }

  override confirmSignIn(params: ConfirmSignInInput): Observable<AuthSignInStep> {
    return defer(async () => await confirmSignIn(params)).pipe(map(output => output.nextStep.signInStep));
  }

  override signOut(): Observable<void> {
    return defer(async () => await signOut());
  }

  override fetchAuthSession(): Observable<AuthSession> {
    return defer(async () => await fetchAuthSession());
  }

  override resetPassword(params: ResetPasswordInput): Observable<AuthSignInStep> {
    return defer(async () => await resetPassword(params)).pipe(map(output => output.nextStep.resetPasswordStep as AuthSignInStep));
  }

  override confirmResetPassword(params: ConfirmResetPasswordInput): Observable<void> {
    return defer(async () => await confirmResetPassword(params));
  }
}
