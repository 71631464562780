import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';

import { HIDE_PROGRESS } from '../models/utility.model';
import { ProgressUseCase } from '../usecases/progress.usecase';

export const progressInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.context.get(HIDE_PROGRESS)) {
    return next(req);
  }
  const progressUseCase = inject(ProgressUseCase);
  const progressId = progressUseCase.show();
  return next(req).pipe(finalize(() => progressUseCase.dismiss(progressId)));
};
