import { Injectable, inject } from '@angular/core';
import { CanActivateFn, CanMatchFn } from '@angular/router';
import { map } from 'rxjs';

import { AuthUseCase } from '../usecases/auth.usecase';

@Injectable()
export class AuthGuard {
  static canActivate: CanActivateFn = () => inject(AuthUseCase).authStep$.pipe(map(step => step === 'DONE'));
  static canMatch: CanMatchFn = () => inject(AuthUseCase).authStep$.pipe(map(step => step === 'DONE'));
}
