import { Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    pathMatch: 'full',
    loadComponent: () => import('./pages/auth/auth.page'),
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/home/home.routes'),
    canActivate: [AuthGuard.canActivate],
    canMatch: [AuthGuard.canMatch],
  },
  {
    path: 'pdf',
    loadComponent: () => import('./pages/pdf/pdf.page'),
    canActivate: [AuthGuard.canActivate],
    canMatch: [AuthGuard.canMatch],
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];
