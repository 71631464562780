import { AuthSession, ConfirmResetPasswordInput, ConfirmSignInInput, ResetPasswordInput, SignInInput } from 'aws-amplify/auth';
import { Observable } from 'rxjs';

import { AuthSignInStep } from '../models/auth.model';

export abstract class AuthGateway {
  abstract signIn(params: SignInInput): Observable<AuthSignInStep>;
  abstract confirmSignIn(params: ConfirmSignInInput): Observable<AuthSignInStep>;
  abstract signOut(): Observable<void>;
  abstract fetchAuthSession(): Observable<AuthSession>;
  abstract resetPassword(params: ResetPasswordInput): Observable<AuthSignInStep>;
  abstract confirmResetPassword(params: ConfirmResetPasswordInput): Observable<void>;
}
