import { Injectable, inject } from '@angular/core';
import dayjs from 'dayjs';
import {
  AsyncSubject,
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  filter,
  finalize,
  first,
  forkJoin,
  from,
  map,
  mergeMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

import {
  ChatMessage,
  CompletionCreateParams,
  Feedback,
  RecordFeedbackParams,
  UnitName,
  UnitNameItem,
  UpdateSuggestionScoreParams,
} from '../models/chat.model';
import { AuthUseCase } from '../usecases/auth.usecase';
import { ChatGateway } from '../usecases/chat.gateway';
import { ChatUseCase } from '../usecases/chat.usecase';

@Injectable()
export class ChatInteractor extends ChatUseCase {
  private readonly _authUseCase = inject(AuthUseCase);
  private readonly _chatGateway = inject(ChatGateway);
  private readonly _initial = new BehaviorSubject<string>('');
  private readonly _isThumbnailDisplay = new BehaviorSubject<boolean>(true);
  private readonly _messages = new BehaviorSubject<ChatMessage[]>([]);
  private readonly _predictions = new BehaviorSubject<string[]>([]);
  private readonly _progress = new BehaviorSubject<boolean>(false);
  private readonly _querySuggestion = new Subject<string>();
  private readonly _unitNames = new BehaviorSubject<UnitNameItem>({});

  constructor() {
    super();
    this._authUseCase.authStep$.pipe(filter(step => step === 'DONE')).subscribe(() => {
      this.reset();
      this._chatGateway.getReference(btoa('unitname/UnitNameTable.json.gz')).subscribe(reference => {
        this._chatGateway.getUnitNameTable(reference.url).subscribe(response => this._unitNames.next(response));
      });
    });
  }

  get messages$(): Observable<ChatMessage[]> {
    return this._messages;
  }
  get progress$(): Observable<boolean> {
    return this._progress;
  }
  get unitNames$(): Observable<UnitName[]> {
    return combineLatest([this._unitNames, this._initial]).pipe(map(([unitNames, initial]) => unitNames[initial] || []));
  }
  get predictions$(): Observable<string[]> {
    return this._predictions;
  }
  get isThumbnailDisplay$(): Observable<boolean> {
    return this._isThumbnailDisplay;
  }
  get querySuggestion$(): Observable<string> {
    return this._querySuggestion;
  }

  send(params: CompletionCreateParams, userInputTime: number): Observable<never> {
    if (this._progress.value) {
      return EMPTY;
    }
    const startTime = Date.now();
    const result = new AsyncSubject<never>();
    this._progress.next(true);
    this._messages.next([...this._messages.value, this.chatMessage({ type: 'question', message: params.question })]);

    const timeout = 15000;
    timer(timeout)
      .pipe(takeUntil(this._progress.pipe(first(progress => !progress))))
      .subscribe(() => {
        this._messages.next([...this._messages.value, this.chatMessage({ type: 'timer' })]);
      });

    forkJoin({
      answer: this._chatGateway.createCompletion(params),
      suggestion: this._chatGateway.getQuerySuggestion(params.question),
    })
      .pipe(finalize(() => this._progress.next(false)))
      .subscribe({
        next: ({ answer: { answer, resources, queryId, date, executionTime: createCompletionTime }, suggestion }) => {
          const references = resources.map((resource, index) => ({
            docTitle: resource.docTitle,
            docPages: resource.docPages,
            docFile$: this._chatGateway.getReference(resource.docKey).pipe(
              tap(response =>
                this._chatGateway
                  .updateExecutionTime(date, {
                    processName: `getReferenceTime_${index}`,
                    executionTime: response.executionTime,
                  })
                  .subscribe(),
              ),
              map(response => ({ url: response.url })),
            ),
            originalPage: resource.originalPage,
            summaries: resource.summaries,
            words: resource.words,
            thumbnailUrl: atob(resource.thumbnailUrl),
          }));
          const data = [
            ...this._messages.value,
            this.chatMessage({
              type: 'answer',
              message: answer,
              references,
              timestamp: dayjs().unix(),
              queryId,
              date,
              suggestions: [suggestion.evaluationTarget],
            }),
          ];
          if (suggestion.query.length) {
            data.push(this.chatMessage({ type: 'suggestion', suggestions: suggestion.query }));
          }
          this._messages.next(data);
          const endTime = Date.now();
          from([
            { processName: 'userInputTime', executionTime: userInputTime },
            { processName: 'createCompletionTime', executionTime: createCompletionTime },
            { processName: 'displayTime', executionTime: endTime - startTime },
          ])
            .pipe(mergeMap(param => this._chatGateway.updateExecutionTime(date, param)))
            .subscribe();
        },
        error: () => {
          this._messages.next([...this._messages.value, this.chatMessage({ type: 'error' })]);
          result.error.bind(result);
        },
        complete: result.complete.bind(result),
      });
    return result;
  }

  reset(): void {
    this._messages.next([this.chatMessage({})]);
  }

  feedback(button: Feedback, message: ChatMessage): void {
    if (button === 'best') {
      message.isBestSelected = !message.isBestSelected;
      message.isBetterSelected = false;
      message.isBadSelected = false;
    } else if (button === 'better') {
      message.isBetterSelected = !message.isBetterSelected;
      message.isBestSelected = false;
      message.isBadSelected = false;
    } else if (button === 'bad') {
      message.isBadSelected = !message.isBadSelected;
      message.isBestSelected = false;
      message.isBetterSelected = false;
    }

    const rate = message.isBestSelected ? 2 : message.isBetterSelected ? 1 : message.isBadSelected ? -1 : 0;
    const params: RecordFeedbackParams = { rate, date: message.date };
    this._chatGateway.updateFeedback(message.queryId, params).subscribe();

    if (message.isBestSelected || message.isBetterSelected || message.isBadSelected) {
      const suggestionScore: UpdateSuggestionScoreParams = {
        column: button,
        value: 1,
      };
      this._chatGateway.updateSuggestionScore(message.suggestions[0], suggestionScore).subscribe();
    }
  }

  fetchUnitNames(unitName: string | null): Observable<never> {
    if (!unitName) {
      this._initial.next('');
    } else {
      this._initial.next(unitName.charAt(0));
    }
    return EMPTY;
  }

  fetchPredictionWords(words: string | null): Observable<never> {
    if (!words) {
      this.clearPredictionWords();
      return EMPTY;
    }

    const result = new AsyncSubject<never>();
    const encodedWords = encodeURIComponent(words);
    this._chatGateway.listPredictionWords(encodedWords).subscribe({
      next: ({ predictions }) => {
        if (predictions.length) {
          this._predictions.next(predictions);
        } else {
          this.clearPredictionWords();
        }
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  clearPredictionWords(): void {
    this._predictions.next([]);
  }

  selectPredictions(selectedIndex: number): string {
    return this._predictions.value[selectedIndex];
  }

  setThumbnailSetting(isDisplay: boolean): void {
    this._isThumbnailDisplay.next(isDisplay);
  }

  postQuerySuggestion(suggestion: string): void {
    this._querySuggestion.next(suggestion);
  }

  updateSuggestionScore(suggestion: string): void {
    this._chatGateway
      .updateSuggestionScore(suggestion, {
        column: 'click',
        value: 1,
      })
      .subscribe();
  }

  private chatMessage = ({
    type = 'default',
    message = '',
    references = [],
    timestamp = dayjs().unix(),
    queryId = '',
    date = 0,
    isBestSelected = false,
    isBetterSelected = false,
    isBadSelected = false,
    suggestions = [],
  }: Partial<ChatMessage>): ChatMessage => {
    return {
      type,
      message,
      references,
      timestamp,
      queryId,
      date,
      isBestSelected,
      isBetterSelected,
      isBadSelected,
      suggestions,
    };
  };
}
