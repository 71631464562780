import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthUsecase } from '../usecases/auth.usecase';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authUsecase: AuthUsecase) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('api')) {
      return this._authUsecase.token$.pipe(mergeMap(token => next.handle(this.appendToken(req, token))));
    }
    return next.handle(req);
  }

  private appendToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return req.clone({
      setHeaders: { ['Authorization']: token },
    });
  }
}
