<router-outlet></router-outlet>
@if (isProgress()) {
  <div class="app-progress">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
}
<div class="app-toasts">
  @for (toast of toasts(); track toast) {
    <ngb-toast [class]="toast.type" [autohide]="true" [delay]="toast.delay" (hidden)="toast.hide()">
      {{ toast.message | translate }}
    </ngb-toast>
  }
</div>
