import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { AuthInteractor } from './interactors/auth.interactor';
import { ChatInteractor } from './interactors/chat.interactor';
import { ProgressInteractor } from './interactors/progress.interactor';
import { ToastInteractor } from './interactors/toast.interactor';
import { authInterceptor } from './interceptors/auth.interceptor';
import { corsInterceptor } from './interceptors/cors.interceptor';
import { progressInterceptor } from './interceptors/progress.interceptor';
import { AuthService } from './services/auth.service';
import { ChatService } from './services/chat.service';
import { AuthGateway } from './usecases/auth.gateway';
import { AuthUseCase } from './usecases/auth.usecase';
import { ChatGateway } from './usecases/chat.gateway';
import { ChatUseCase } from './usecases/chat.usecase';
import { ProgressUseCase } from './usecases/progress.usecase';
import { ToastUseCase } from './usecases/toast.usecase';

export const provideCore = (): EnvironmentProviders => {
  return makeEnvironmentProviders([
    { provide: AuthGateway, useClass: AuthService },
    { provide: ChatGateway, useClass: ChatService },
    { provide: AuthUseCase, useClass: AuthInteractor },
    { provide: ChatUseCase, useClass: ChatInteractor },
    { provide: ProgressUseCase, useClass: ProgressInteractor },
    { provide: ToastUseCase, useClass: ToastInteractor },
  ]);
};

export const interceptors = [authInterceptor, corsInterceptor, progressInterceptor];
