<router-outlet></router-outlet>
<div *ngIf="progress$ | async" class="app-progress">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div class="app-toasts">
  <ngb-toast *ngFor="let toast of toasts$ | async" [class]="toast.type" [autohide]="true" [delay]="toast.delay" (hidden)="toast.hide()">
    {{ toast.message | translate }}
  </ngb-toast>
</div>
