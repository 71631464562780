import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import {
  AsyncSubject,
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  filter,
  finalize,
  first,
  forkJoin,
  from,
  map,
  mergeMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

import { ChatMessage, Feedback, RecordFeedbackParams, UpdateSuggestionScoreParams } from '../models/chat.model';
import { CompletionCreateParams } from '../models/completion.model';
import { UnitName, UnitNameItem } from '../models/prediction.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { ChatUsecase } from '../usecases/chat.usecase';
import { CompletionGateway } from '../usecases/completion.gateway';
import { PredictionGateway } from '../usecases/prediction.gateway';
import { RecordGateway } from '../usecases/record.gateway';
import { ReferenceGateway } from '../usecases/reference.gateway';
import { TimeGateway } from '../usecases/time.gateway';

@Injectable()
export class ChatInteractor extends ChatUsecase {
  get messages$(): Observable<ChatMessage[]> {
    return this._messages;
  }
  get progress$(): Observable<boolean> {
    return this._progress;
  }
  get unitNames$(): Observable<UnitName[]> {
    return combineLatest([this._unitNames, this._initial]).pipe(map(([unitNames, initial]) => unitNames[initial] || []));
  }
  get predictions$(): Observable<string[]> {
    return this._predictions;
  }
  get isThumbnailDisplay$(): Observable<boolean> {
    return this._isThumbnailDisplay;
  }
  get querySuggestion$(): Observable<string> {
    return this._querySuggestion;
  }

  private readonly _messages = new BehaviorSubject<ChatMessage[]>([]);
  private readonly _progress = new BehaviorSubject<boolean>(false);
  private readonly _unitNames = new BehaviorSubject<UnitNameItem>({});
  private readonly _initial = new BehaviorSubject<string>('');
  private readonly _predictions = new BehaviorSubject<string[]>([]);
  private readonly _isThumbnailDisplay = new BehaviorSubject<boolean>(true);
  private readonly _querySuggestion = new Subject<string>();

  constructor(
    private _authUsecase: AuthUsecase,
    private _completionGateway: CompletionGateway,
    private _predictionGateway: PredictionGateway,
    private _recordGateway: RecordGateway,
    private _referenceGateway: ReferenceGateway,
    private _timeGateway: TimeGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this.reset();
      this._referenceGateway.getReference(btoa('unitname/UnitNameTable.json.gz')).subscribe(reference => {
        this._referenceGateway.getUnitNameTable(reference.url).subscribe(response => this._unitNames.next(response));
      });
    });
  }

  send(params: CompletionCreateParams, userInputTime: number): Observable<never> {
    if (this._progress.value) {
      return EMPTY;
    }
    const startTime = Date.now();
    const result = new AsyncSubject<never>();
    this._progress.next(true);
    this._messages.next([
      ...this._messages.value,
      {
        type: 'question',
        message: params.question,
        timestamp: dayjs().unix(),
        references: [],
        queryId: '',
        date: 0,
        isBestSelected: false,
        isBetterSelected: false,
        isBadSelected: false,
        suggestions: [],
      },
    ]);

    const timeout = 15000;
    timer(timeout)
      .pipe(takeUntil(this._progress.pipe(first(progress => !progress))))
      .subscribe(() => {
        this._messages.next([
          ...this._messages.value,
          {
            type: 'timer',
            message: '',
            timestamp: dayjs().unix(),
            references: [],
            queryId: '',
            date: 0,
            isBestSelected: false,
            isBetterSelected: false,
            isBadSelected: false,
            suggestions: [],
          },
        ] as ChatMessage[]);
      });

    forkJoin({
      answer: this._completionGateway.createCompletion(params),
      suggestions: this._predictionGateway.getQuerySuggestion(params.question),
    })
      .pipe(finalize(() => this._progress.next(false)))
      .subscribe({
        next: ({ answer: { answer, resources, queryId, date, executionTime: createCompletionTime }, suggestions }) => {
          const data = [
            ...this._messages.value,
            {
              type: 'answer',
              message: answer,
              references: resources.map((resource, index) => ({
                docTitle: resource.docTitle,
                docPages: resource.docPages,
                docFile$: this._referenceGateway.getReference(resource.docKey).pipe(
                  tap(response =>
                    this._timeGateway
                      .updateExecutionTime(date, {
                        processName: `getReferenceTime_${index}`,
                        executionTime: response.executionTime,
                      })
                      .subscribe(),
                  ),
                  map(response => ({ url: response.url })),
                ),
                originalPage: resource.originalPage,
                summaries: resource.summaries,
                words: resource.words,
                thumbnailUrl: atob(resource.thumbnailUrl),
              })),
              timestamp: dayjs().unix(),
              queryId,
              date,
              isBestSelected: false,
              isBetterSelected: false,
              isBadSelected: false,
              suggestions: [suggestions.evaluationTarget],
            },
          ] as ChatMessage[];
          if (suggestions.query.length) {
            data.push({
              type: 'suggestion',
              message: '',
              timestamp: dayjs().unix(),
              references: [],
              queryId: '',
              date: 0,
              isBestSelected: false,
              isBetterSelected: false,
              isBadSelected: false,
              suggestions: suggestions.query,
            });
          }
          this._messages.next(data);
          const endTime = Date.now();
          from([
            {
              processName: 'userInputTime',
              executionTime: userInputTime,
            },
            {
              processName: 'createCompletionTime',
              executionTime: createCompletionTime,
            },
            {
              processName: 'displayTime',
              executionTime: endTime - startTime,
            },
          ])
            .pipe(mergeMap(param => this._timeGateway.updateExecutionTime(date, param)))
            .subscribe();
        },
        error: () => {
          this._messages.next([
            ...this._messages.value,
            {
              type: 'error',
              message: '',
              timestamp: dayjs().unix(),
              references: [],
              queryId: '',
              date: 0,
              isBestSelected: false,
              isBetterSelected: false,
              isBadSelected: false,
              suggestions: [],
            },
          ] as ChatMessage[]);
          result.error.bind(result);
        },
        complete: result.complete.bind(result),
      });
    return result;
  }

  reset(): void {
    this._messages.next([
      {
        type: 'answer',
        message: '質問内容を入力して下さい。',
        timestamp: dayjs().unix(),
        references: [],
        queryId: '',
        date: 0,
        isBestSelected: false,
        isBetterSelected: false,
        isBadSelected: false,
        suggestions: [],
      },
    ]);
  }

  feedback(button: Feedback, message: ChatMessage): void {
    if (button === 'best') {
      message.isBestSelected = !message.isBestSelected;
      message.isBetterSelected = false;
      message.isBadSelected = false;
    } else if (button === 'better') {
      message.isBetterSelected = !message.isBetterSelected;
      message.isBestSelected = false;
      message.isBadSelected = false;
    } else if (button === 'bad') {
      message.isBadSelected = !message.isBadSelected;
      message.isBestSelected = false;
      message.isBetterSelected = false;
    }

    const rate = message.isBestSelected ? 2 : message.isBetterSelected ? 1 : message.isBadSelected ? -1 : 0;
    const params: RecordFeedbackParams = { rate, date: message.date };
    this._recordGateway.updateFeedbuck(message.queryId, params).subscribe();

    if (message.isBestSelected || message.isBetterSelected || message.isBadSelected) {
      const suggestionScore: UpdateSuggestionScoreParams = {
        column: button,
        value: 1,
      };
      this._recordGateway.updateSuggestionScore(message.suggestions[0], suggestionScore).subscribe();
    }
  }

  fetchUnitNames(unitName: string | null): Observable<never> {
    if (!unitName) {
      this._initial.next('');
    } else {
      this._initial.next(unitName.charAt(0));
    }
    return EMPTY;
  }

  fetchPredictionWords(words: string | null): Observable<never> {
    if (!words) {
      this.clearPredictionWords();
      return EMPTY;
    }

    const result = new AsyncSubject<never>();
    const encodedWords = encodeURIComponent(words);
    this._predictionGateway.listPredictionWords(encodedWords).subscribe({
      next: ({ predictions }) => {
        if (predictions.length) {
          this._predictions.next(predictions);
        } else {
          this.clearPredictionWords();
        }
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  clearPredictionWords(): void {
    this._predictions.next([]);
  }

  selectPredictions(selectedIndex: number): string {
    return this._predictions.value[selectedIndex];
  }

  setThumbnailSetting(isDisplay: boolean): void {
    this._isThumbnailDisplay.next(isDisplay);
  }

  postQuerySuggestion(suggestion: string): void {
    this._querySuggestion.next(suggestion);
  }

  updateSuggestionScore(suggestion: string): void {
    this._recordGateway
      .updateSuggestionScore(suggestion, {
        column: 'click',
        value: 1,
      })
      .subscribe();
  }
}
