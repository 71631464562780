import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Reference } from '../models/chat.model';
import { UnitNameItem } from '../models/prediction.model';
import { ReferenceGateway } from '../usecases/reference.gateway';

@Injectable()
export class ReferenceService extends ReferenceGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  getReference(docKey: string): Observable<Reference> {
    return this._http.get<Reference>(`api/ask/references/${docKey}`);
  }

  getUnitNameTable(url: string): Observable<UnitNameItem> {
    return this._http.get<UnitNameItem>(url);
  }
}
